<template>
  <div>
    <!-- ABOUT CARD -->
    <vx-card :title="`Detail ${$i18n.t('InvoiceCustomer')}`" class="mt-base">
      <!-- ACTION SLOT -->
      <template slot="actions">
        <div style="display: flex; align-items: center; justify-content: center;">
          <vs-button color="primary" class="mr-3" icon-pack="feather" icon="icon-edit" @click="$router.push({ name: 'invoice-customer-edit', params: { id: $route.params.id } })">
            {{$i18n.t('Edit')}}
          </vs-button>
          <vs-button color="danger" icon-pack="feather" icon="icon-arrow-left" type="border" @click="$router.push({ name: 'invoice-customer' })">
            {{ $i18n.t('Back') }}
          </vs-button>
        </div>

      </template>

      <div class="vx-row">
        <div class="vx-col w-full lg:w-1/2" v-for="(cell, i) in cells" :key="i">
          <div class="mt-5">
            <h6 class="font-bold mb-1">{{ cell.header }}</h6>
            <p>
                  <span v-if="cell.format">
                    {{ cell.format(item[cell.field]) }}
                  </span>
              <span v-else>
                    {{ item[cell.field] }}
                  </span>
            </p>
          </div>
        </div>
      </div>
    </vx-card>

  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "InvoiceCustomerDetail",
  data: () => ({

  }),
  computed: {
    ...mapGetters({
      showItem: 'invoiceCustomer/showItem',
      items: 'invoiceCustomer/getItems'
    }),
    item () {
      return this.showItem(this.id);
    },
    cells () {
      return [
        {
          header: this.$i18n.t('DateIssued'),
          field: 'date_issued'
        },
        {
          header: this.$i18n.t('CustomerName'),
          field: 'customer'
        },
        {
          header: this.$i18n.t('DateDue'),
          field: 'date_due',
          format: (value) => {
            if (value) {
              return value;
            }

            return '-';
          }
        },
        {
          header: this.$i18n.t('NPWPCustomerNumber'),
          field: 'no_npwp_customer'
        },
        {
          header: this.$i18n.t('InvoiceDate'),
          field: 'invoice_date'
        },
        {
          header: this.$i18n.t('SPPNumber'),
          field: 'spp_number'
        },
        {
          header: this.$i18n.t('POContactNumber'),
          field: 'no_po_kontrak'
        },
        {
          header: this.$i18n.t('InvoiceNumber'),
          field: 'invoice_number'
        },
        {
          header: this.$i18n.t('PODate'),
          field: 'po_date'
        },
        {
          header: this.$i18n.t('DODate'),
          field: 'do_date',
          format: (value) => { 
            if (value) {
              return value;
            }

            return '-';
          }
        },
        {
          header: this.$i18n.t('ReceiptNumber'),
          field: 'kwitansi_number'
        },
        {
          header: this.$i18n.t('DONumber'),
          field: 'do_number'
        },
        {
          header: this.$i18n.t('TaxInvoiceDate'),
          field: 'tgl_faktur_pajak'
        },
        {
          header: this.$i18n.t('TotalBillValue'),
          field: 'nilai_total_tagihan',
          format: (value) => {
            let val = (value/1).toFixed(0).replace('.', ',')
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
          }
        },
        {
          header: this.$i18n.t('TaxInvoiceNumber'),
          field: 'no_faktur_pajak'
        },
        {
          header: this.$i18n.t('TotalInvoiceValue'),
          field: 'invoice',
          format: (value) => {
            let val = (value/1).toFixed(0).replace('.', ',')
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
          }
        },
        {
          header: this.$i18n.t('InvoiceDescription'),
          field: 'ket_penagihan'
        },
        {
          header: this.$i18n.t('VAT'),
          field: 'pajak',
          format: (value) => {
            console.log(value)
            let val = (value/1).toFixed(0).replace('.', ',')
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
          }
        },
        {
          header: this.$i18n.t('Description'),
          field: 'keterangan'
        },
        {
          header: this.$i18n.t('PaymentAccount'),
          field: 'rekening_pembayaran'
        },
      ]
    }
  },
  created () {
    this.id = this.$secure.decrypt(this.$route.params.id);
    this.$store.dispatch('invoiceCustomer/fetchItems');
  }
}
</script>

<style scoped>

</style>
